<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";


export default {
  components: {
    Layout,
  },
  data() {
    return {
      appointment: [],
      doctor: [],
      user: [],
      timeSlots: [],
      booked: [],
      config: []
    };
  },
  methods: {
    async getAppointment() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/appointment/" + this.$route.params.id
      );
      this.appointment = response.data;
      // console.log(this.appointment.doctorId)


      await axios.get('https://api.doctosoft.com/index.php/api/doctor').then((response) => {

        this.doctor = response.data
      })

      await axios.get('https://api.doctosoft.com/index.php/api/user').then((response) => {

        this.user = response.data
      })
    },
    successmsg() {
      Swal.fire("Good job!", "Updated the appointment details ", "success");
    },
    async getBookedSlot() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/book/appointment/" + this.appointment.doctorId + '/' + this.appointment.date
      );
      this.booked = response.data;
      this.getSlots();
    },
    async getSlots() {
      const response = await axios.get("https://api.doctosoft.com/index.php/api/app/config/");
      this.config = response.data.config;

      this.timeSlots = [];
      const startTime = this.config.startTime;
      const endTime = this.config.endTime;
      const timeSlotDuration = parseInt(this.config.interval); // duration of each time slot in minutes

      let currentTime = startTime;

      while (currentTime < endTime) {
        const start = currentTime;
        const end = addMinutes(currentTime, timeSlotDuration);
        if(end > endTime)
        {
          break;
        }
        const timeSlot = start + "-" + end;
        this.timeSlots.push(timeSlot);
        currentTime = end;
      }
      function addMinutes(time, minutes) {
        const [hours, mins] = time.split(":").map(Number);
        const timeInMinutes = hours * 60 + mins + minutes;
        const newHours = Math.floor(timeInMinutes / 60);
        const newMins = timeInMinutes % 60;
        return `${newHours.toString().padStart(2, "0")}:${newMins
          .toString()
          .padStart(2, "0")}`;
      }
    },
    async updateAppointment() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("doctor", this.appointment.doctorId);
      urlencoded.append("user", this.appointment.userId);
      urlencoded.append("reason", this.appointment.reason);
      urlencoded.append("time", this.appointment.time);
      urlencoded.append("date", this.appointment.date);
      urlencoded.append("doctorComments", this.appointment.doctorComments);
      urlencoded.append("status", this.appointment.status);

      await axios.put(
        "https://api.doctosoft.com/index.php/api/appointment" + "/" + this.$route.params.id,
        urlencoded
      );
      this.successmsg()
      this.$router.push("/admin/appointment");
    },
  },
  created() {
    let currentDate = new Date();
    let nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + this.config.day);
    let startYear = currentDate.getFullYear();
    let startMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let startDay = currentDate.getDate().toString().padStart(2, "0");
    let endYear = nextDate.getFullYear();
    let endMonth = (nextDate.getMonth() + 1).toString().padStart(2, "0");
    let endDay = nextDate.getDate().toString().padStart(2, "0");
    this.startDate = `${startYear}-${startMonth}-${startDay}`;
    this.endDate = `${endYear}-${endMonth}-${endDay}`;
    this.getAppointment();
    this.getSlots();
  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/appointment')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/appointment')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="updateAppointment">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="updateAppointment">

            <div class="mb-3">
              <label for="doctor" class="form-label">Doctor</label>
              <select class="form-select" aria-label="size 3 select example" v-model="appointment.doctorId" @change="getBookedSlot()">
                <option v-for="doc in doctor" :key="doc.id" :value="doc.id">
                  {{ doc.name }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="user" class="form-label">Name</label>
              <select class="form-select" aria-label="size 3 select example" v-model="appointment.userId">
                <option v-for="users in user" :key="users.id" :value="users.id">
                  {{ users.name }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="reason" class="form-label">Reason <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="appointment.reason" placeholder="Enter reason" />
            </div>

            <div class="mb-3">
              <label for="date" class="form-label">Date <span class="text-danger">*</span></label>
              <input type="date" :min="startDate" :max="endDate" v-model="appointment.date" class="form-control"
                id="exampleInputdate" @change="getBookedSlot"/>
            </div>

            <div class="mb-3">
              <!-- <label for="time" class="form-label">Time <span class="text-danger">*</span></label> -->
              <div v-for="slot in timeSlots" :key="slot" class="slot-item">
                <input type="radio" :id="slot" name="time" :value="slot" :disabled="booked.includes(slot)"
                  v-model="appointment.time" />
                <label :for="slot"> {{ slot }}</label>
              </div>
            </div>

            <div class="mb-3">
              <label for="comment" class="form-label">Doctor's Comment <span class="text-danger">*</span></label>
              <textarea rows=10 class="form-control" v-model="appointment.doctorComments" placeholder="Enter comment" />
            </div>

            <!-- <div class="mb-3">
              <label for="status" class="form-label">Status <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="appointment.status" placeholder="Enter status" />
            </div> -->

            <div class="mb-3">
              <label for="status" class="form-label">Status <span class="text-danger">*</span></label>
              <select class="form-select" aria-label="size 3 select example" v-model="appointment.status">
                <option value="new">New</option>
                <option value="accept">Accept</option>
                <option value="reject">Reject</option>
                <option value="pending">Pending</option>
              </select>
            </div>

          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
